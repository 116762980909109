import _defineProperty from "/vercel/path0/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

export var indentifyUser = function indentifyUser(user) {
  if (true && !!window.analytics) {
    window.analytics.identify(user.id, {
      created: user.createdAt,
      userId: user.id,
      name: "".concat(user.firstName, " ").concat(user.lastName),
      email: user.email
    });
    userAccountCreated(user);
  }
};
export var identifyLoggedInUser = function identifyLoggedInUser(user) {
  if (false || !window.analytics || !(user !== null && user !== void 0 && user.id) || !(user !== null && user !== void 0 && user.email)) return;
  var payload = {
    userId: user.id,
    email: user.email,
    event: 'User Logged In'
  };
  if (user.firstName && user.lastName) payload['name'] = "".concat(user.firstName, " ").concat(user.lastName);
  window.analytics.identify(user.id, payload);
};
export var userAccountCreated = function userAccountCreated(user) {
  if (true && !!window.analytics) {
    window.analytics.track('User Account Created', {
      recommendationEmails: user.recommendationEmails
    });
  }
};
export var providerCreated = function providerCreated(provider) {
  var id = provider.id,
      name = provider.name,
      slug = provider.slug,
      newsletterEmail = provider.newsletterEmail;

  if (true && !!window.analytics) {
    window.analytics.track('Provider Created', {
      providerName: name,
      providerSlug: slug,
      newsletterEmail: newsletterEmail,
      providerId: id
    });
  }
};
export var serviceCreated = function serviceCreated(service) {
  if (true && !!window.analytics) {
    window.analytics.track('Service Created', {
      serviceName: service.name,
      serviceSlug: service.slug,
      serviceCurrency: service.currency,
      serviceTimezone: service.timezone,
      serviceId: service.id
    });
  }
};
export var serviceLeadCreated = function serviceLeadCreated(serviceLeadData) {
  var providerService = serviceLeadData.providerService,
      currentUser = serviceLeadData.currentUser;
  var id = providerService.id,
      name = providerService.name,
      provider = providerService.provider;

  if (true && !!window.analytics) {
    window.analytics.track('Service Lead Created', {
      serviceLeadId: currentUser === null || currentUser === void 0 ? void 0 : currentUser.id,
      serviceId: id,
      serviceName: name,
      providerId: provider.id,
      providerName: provider.name
    });
  }
};
export var postCreated = function postCreated(postData) {
  var serviceName = postData.serviceName,
      serviceId = postData.serviceId,
      provider = postData.provider,
      postId = postData.postId,
      title = postData.title;

  if (true && !!window.analytics) {
    window.analytics.track('Post Created', {
      postId: postId,
      postTitle: title,
      serviceName: serviceName,
      serviceId: serviceId,
      providerId: provider === null || provider === void 0 ? void 0 : provider.id,
      providerName: provider === null || provider === void 0 ? void 0 : provider.name
    });
  }
};
export var postPublished = function postPublished(postData) {
  var serviceName = postData.serviceName,
      serviceId = postData.serviceId,
      provider = postData.provider,
      postId = postData.postId,
      title = postData.title;

  if (true && !!window.analytics) {
    window.analytics.track('Post Published', {
      postId: postId,
      postTitle: title,
      serviceName: serviceName,
      serviceId: serviceId,
      providerId: provider === null || provider === void 0 ? void 0 : provider.id,
      providerName: provider === null || provider === void 0 ? void 0 : provider.name
    });
  }
};
export var bankConnected = function bankConnected(bankData) {
  var currentService = bankData.currentService,
      provider = bankData.provider;

  if (true && !!window.analytics) {
    window.analytics.track('Bank Connected', {
      serviceId: currentService === null || currentService === void 0 ? void 0 : currentService.id,
      serviceName: currentService === null || currentService === void 0 ? void 0 : currentService.name,
      providerId: provider === null || provider === void 0 ? void 0 : provider.id,
      providerName: provider === null || provider === void 0 ? void 0 : provider.name
    });
  }
};
export var subscriptionCreated = function subscriptionCreated(subscriptionData) {
  var id = subscriptionData.id,
      plan = subscriptionData.plan,
      providerService = subscriptionData.providerService;

  if (true && !!window.analytics) {
    window.analytics.track('Subscription Created', {
      subscriptionId: id,
      planId: plan.id,
      planName: plan.name,
      serviceId: providerService.id,
      serviceName: providerService.name,
      providerId: providerService === null || providerService === void 0 ? void 0 : providerService.provider.id,
      providerName: providerService === null || providerService === void 0 ? void 0 : providerService.provider.name
    });
  }
};
export var sessionCreated = function sessionCreated(sessionData) {
  var activityResponse = sessionData.activityResponse,
      provider = sessionData.provider,
      providerSelectedService = sessionData.providerSelectedService;
  var activityId = activityResponse.activityId,
      name = activityResponse.name,
      priceCents = activityResponse.priceCents,
      virtual = activityResponse.virtual,
      capacity = activityResponse.capacity;

  if (true && !!window.analytics) {
    window.analytics.track('Session Created', {
      activityId: activityId,
      name: name,
      priceCents: priceCents,
      virtual: virtual,
      capacity: capacity,
      serviceName: providerSelectedService === null || providerSelectedService === void 0 ? void 0 : providerSelectedService.name,
      providerId: provider.id,
      providerName: provider.name
    });
  }
};
export var bookingCreated = function bookingCreated(bookingData) {
  var booking = bookingData.booking,
      serviceId = bookingData.serviceId,
      providerService = bookingData.providerService,
      session = bookingData.session;

  if (true && !!window.analytics) {
    var _providerService$prov, _providerService$prov2;

    window.analytics.track('Booking Created', {
      bookingId: booking.id,
      sessionId: booking.session.id,
      sessionName: session === null || session === void 0 ? void 0 : session.name,
      serviceId: serviceId,
      serviceName: providerService === null || providerService === void 0 ? void 0 : providerService.name,
      providerId: providerService === null || providerService === void 0 ? void 0 : (_providerService$prov = providerService.provider) === null || _providerService$prov === void 0 ? void 0 : _providerService$prov.id,
      providerName: providerService === null || providerService === void 0 ? void 0 : (_providerService$prov2 = providerService.provider) === null || _providerService$prov2 === void 0 ? void 0 : _providerService$prov2.name
    });
  }
};
export var serviceCustomerCreated = function serviceCustomerCreated(serviceCustomerData) {
  var user = serviceCustomerData.user,
      serviceId = serviceCustomerData.serviceId,
      providerServices = serviceCustomerData.providerServices,
      serviceType = serviceCustomerData.serviceType;

  if (true && !!window.analytics) {
    window.analytics.track('Service Customer Created', {
      serviceCustomerId: user.id,
      serviceId: serviceId,
      serviceName: providerServices === null || providerServices === void 0 ? void 0 : providerServices.name,
      providerId: providerServices === null || providerServices === void 0 ? void 0 : providerServices.provider.id,
      providerName: providerServices === null || providerServices === void 0 ? void 0 : providerServices.provider.name,
      serviceType: serviceType
    });
  }
};
export var surveyAnswersSubmitted = function surveyAnswersSubmitted(surveyAnswer) {
  var survey = surveyAnswer.survey,
      serviceProspectId = surveyAnswer.serviceProspectId,
      provider = surveyAnswer.provider;

  if (true && !!window.analytics) {
    window.analytics.track('Survey Answers Submitted', {
      surveyId: survey.id,
      surveyName: survey === null || survey === void 0 ? void 0 : survey.name,
      serviceId: serviceProspectId,
      serviceName: survey.providerService.name,
      providerId: provider.id,
      providerName: provider === null || provider === void 0 ? void 0 : provider.name
    });
  }
};
export var emailCampaignCreated = function emailCampaignCreated(emailCampaignData) {
  var serviceId = emailCampaignData.serviceId,
      serviceName = emailCampaignData.serviceName,
      post = emailCampaignData.post,
      audience = emailCampaignData.audience,
      provider = emailCampaignData.provider;

  if (true && !!window.analytics) {
    window.analytics.track('Email Campaign Created', {
      emailTarget: audience,
      postId: post.id,
      postTitle: post === null || post === void 0 ? void 0 : post.title,
      serviceId: serviceId,
      serviceName: serviceName,
      providerId: provider.id,
      providerName: provider.name
    });
  }
};
export var planCreated = function planCreated(planData) {
  if (true && !!window.analytics) {
    window.analytics.track('Plan Created', _objectSpread({}, planData));
  }
};
export var surveyCreated = function surveyCreated(surveyCreatedData) {
  var serviceId = surveyCreatedData.serviceId,
      name = surveyCreatedData.name,
      questions = surveyCreatedData.questions;

  if (true && !!window.analytics) {
    window.analytics.track('Survey Created', {
      surveyName: name,
      serviceId: serviceId,
      questions: questions
    });
  }
};
export var surveyQuestionCreated = function surveyQuestionCreated(surveyQuestionData) {
  if (true && !!window.analytics) {
    window.analytics.track('Survey Question Created', _objectSpread({}, surveyQuestionData));
  }
};
export var bookingCanceled = function bookingCanceled(bookingData) {
  var id = bookingData.id,
      providerService = bookingData.providerService,
      session = bookingData.session;

  if (true && !!window.analytics) {
    var _providerService$prov3, _providerService$prov4;

    window.analytics.track('Booking Canceled', {
      bookingId: id,
      sessionId: session.id,
      sessionName: session === null || session === void 0 ? void 0 : session.name,
      serviceId: providerService === null || providerService === void 0 ? void 0 : providerService.id,
      serviceName: providerService === null || providerService === void 0 ? void 0 : providerService.name,
      providerId: providerService === null || providerService === void 0 ? void 0 : (_providerService$prov3 = providerService.provider) === null || _providerService$prov3 === void 0 ? void 0 : _providerService$prov3.id,
      providerName: providerService === null || providerService === void 0 ? void 0 : (_providerService$prov4 = providerService.provider) === null || _providerService$prov4 === void 0 ? void 0 : _providerService$prov4.name
    });
  }
};
export var subscriptionCanceled = function subscriptionCanceled(subscriptionData) {
  var id = subscriptionData.id,
      plan = subscriptionData.plan,
      providerService = subscriptionData.providerService,
      customerId = subscriptionData.customerId;

  if (true && !!window.analytics) {
    window.analytics.track('Subscription Canceled', {
      subscriptionId: id,
      planId: plan === null || plan === void 0 ? void 0 : plan.id,
      planName: plan === null || plan === void 0 ? void 0 : plan.name,
      serviceId: providerService === null || providerService === void 0 ? void 0 : providerService.id,
      serviceName: providerService === null || providerService === void 0 ? void 0 : providerService.name,
      providerId: providerService === null || providerService === void 0 ? void 0 : providerService.provider.id,
      providerName: providerService === null || providerService === void 0 ? void 0 : providerService.provider.name,
      customerId: customerId
    });
  }
};
export var serviceLeadCSVUploaded = function serviceLeadCSVUploaded(serviceLeadData) {
  if (true && !!window.analytics) {
    var _serviceLeadData$prov, _serviceLeadData$prov2;

    window.analytics.track('Service Lead CSV Uploaded', {
      serviceId: serviceLeadData.serviceId,
      serviceName: (_serviceLeadData$prov = serviceLeadData.providerService) === null || _serviceLeadData$prov === void 0 ? void 0 : _serviceLeadData$prov.name,
      providerId: (_serviceLeadData$prov2 = serviceLeadData.providerService) === null || _serviceLeadData$prov2 === void 0 ? void 0 : _serviceLeadData$prov2.providerId
    });
  }
};
export var completedWaitlistSurvey = function completedWaitlistSurvey(surveyData) {
  if (true && !!window.analytics) {
    var survey = surveyData.survey,
        providerService = surveyData.providerService,
        provider = surveyData.provider,
        planId = surveyData.planId;
    if (!planId) return;
    window.analytics.track('Completed Waitlist Survey', {
      surveyId: survey === null || survey === void 0 ? void 0 : survey.id,
      serviceId: providerService === null || providerService === void 0 ? void 0 : providerService.id,
      serviceName: providerService === null || providerService === void 0 ? void 0 : providerService.name,
      providerId: provider === null || provider === void 0 ? void 0 : provider.id,
      providerName: provider === null || provider === void 0 ? void 0 : provider.name,
      planId: planId
    });
  }
};
export var joinedWaitlist = function joinedWaitlist(waitlistData) {
  if (true && !!window.analytics) {
    var _plan$providerService, _service$provider, _service$provider2;

    var plan = waitlistData.plan,
        userId = waitlistData.userId,
        service = waitlistData.service;
    if (!userId) return;
    window.analytics.track('Joined Waitlist', {
      serviceId: plan === null || plan === void 0 ? void 0 : (_plan$providerService = plan.providerService) === null || _plan$providerService === void 0 ? void 0 : _plan$providerService.id,
      planId: plan === null || plan === void 0 ? void 0 : plan.id,
      planName: plan === null || plan === void 0 ? void 0 : plan.name,
      serviceName: service === null || service === void 0 ? void 0 : service.name,
      providerId: service === null || service === void 0 ? void 0 : (_service$provider = service.provider) === null || _service$provider === void 0 ? void 0 : _service$provider.id,
      providerName: service === null || service === void 0 ? void 0 : (_service$provider2 = service.provider) === null || _service$provider2 === void 0 ? void 0 : _service$provider2.name,
      userId: userId
    });
  }
};